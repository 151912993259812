import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextareaAutosize, useTheme } from '@mui/material'
import { useAppDispatch } from '../../../store'
import { deleteFile, saveAnswer, uploadFile } from '../../../store/chatsWritten/actions'
import { IMessageWritten } from '../../../store/chatsWritten/types'
import { EditorDefault } from '../../Editors/EditorDefault'
import { parseLink } from '../../../utils/parseLink'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import AttachmentIcon from '@mui/icons-material/Attachment'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { AlertMessage } from '../../AlertMessage'
import DownloadIcon from '@mui/icons-material/Download'
import { URI } from '../../../utils/config'
import { styles } from './Styles'
import { RejectedWithValueAction } from '../../../store/types'
import CircularProgress from '@mui/material/CircularProgress'
import { ACCEPT, SIZE_PROG } from '../../../utils/consts'
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'

const valiarnts = [
  {
    absnum: 1,
    name: 'Є відповідь у Системі',
  },
  {
    absnum: 2,
    name: 'Є відповідь у Системі, але рекомендуємо доповнити/уточнити',
  },
  {
    absnum: 3,
    name: 'Нема відповіді в Системі, запитання ситуативне',
  },
]

interface Props {
  closeHandler: () => void
  message: IMessageWritten
  open: boolean
  disabled?: boolean
}

export const AnswerModal: React.FC<Props> = ({ open, message, disabled, closeHandler }) => {
  const [loading, setLoading] = React.useState(false)
  const [file, setFile] = React.useState<File | null>(null)
  const [fileName, setFileName] = React.useState<string | undefined>('')
  const [variantId, setVariantId] = React.useState(message.variant_id || '')
  const [comment, setComment] = React.useState(message.comment || '')
  const [error, setError] = React.useState(false)
  const [errors, setErrors] = React.useState('')

  const editorRef = React.useRef<any | null>(null)

  const theme = useTheme()
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    setFileName(message.attachment)
  }, [message.attachment])

  const handleSaveAndswer = async (isPublic: number) => {
    try {
      if (!variantId) return setError(true)
      if (!message.absnum || !editorRef.current || loading) return null
      const body = editorRef.current.getContent()
      setError(false)
      setLoading(true)
      if (file) {
        const fd = new FormData()
        fd.append('file', file)
        fd.append('message_id', String(message.absnum))
        fd.append('part_id', String(message.part_id))
        fd.append('file_name', file.name)
        await dispatch(uploadFile(fd))
      }

      dispatch(
        saveAnswer({
          message_id: message.absnum,
          part_id: message.part_id,
          is_public: isPublic,
          variant_id: Number(variantId),
          body,
          comment,
        })
      ).then((res) => {
        if (res.meta.requestStatus === 'rejected') {
          const { error } = res as RejectedWithValueAction<any, any>
          if (error.message) {
            setErrors(error.message)
          } else {
            setErrors('Сталася помилка')
          }
          setLoading(false)
        } else {
          setLoading(false)
          closeHandler()
        }
      })
    } catch (err) {
      setLoading(false)
      setErrors('Сталася помилка')
      console.log(err)
    }
  }

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null
    setFile(file)
    setFileName(event.target.value)
  }

  const handleDeleteFile = () => {
    if (message.attachment && message) {
      dispatch(deleteFile({ id: message.absnum, partId: message.part_id }))
    }
  }

  const handleDownload = () => {
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        if (reader.result) {
          const link = document.createElement('a')
          link.href = reader.result as string
          link.setAttribute('download', file.name)
          document.body.appendChild(link)
          link.click()
          link.parentNode && link.parentNode.removeChild(link)
        }
      }
      reader.readAsDataURL(file)
    } else if (message.attachment) {
      const link = document.createElement('a')
      link.href = `${URI}/${message.attachment}`
      link.setAttribute('download', message.attachment.split(/(\\|\/)/g).pop() || '')
      document.body.appendChild(link)
      link.click()
      link.parentNode && link.parentNode.removeChild(link)
    }
  }

  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      disableEnforceFocus
    >
      <Box sx={{ ...styles, width: 960 }}>
        <Stack direction={'column'}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Питання
          </Typography>
          <WrapperMessageS>
            <Typography dangerouslySetInnerHTML={{ __html: parseLink(message.question) }} />
          </WrapperMessageS>
          <IconButton
            onClick={closeHandler}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        <Stack pt={2} direction={'column'}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Відповідь
          </Typography>
          <EditorDefault disabled={disabled} editorRef={editorRef} initialValue={message.body} />
        </Stack>
        <Stack direction={'row'}>
          <IconButton component='label' sx={{ ml: 1 }}>
            <AttachFileIcon color={'action'} />
            <input disabled={disabled} type='file' hidden accept={ACCEPT} onChange={handleChangeFile} />
          </IconButton>
          {fileName && (
            <Stack
              color={theme.palette.primary.main}
              py={1}
              direction={'row'}
              spacing={2}
              alignItems={'center'}
              justifyContent={'flex-end'}
            >
              <AttachmentIcon />
              <Typography>{fileName.split(/(\\|\/)/g).pop()}</Typography>
              {message.attachment ? (
                <IconButton color={'default'} onClick={handleDownload}>
                  <DownloadIcon />
                </IconButton>
              ) : null}
              <IconButton color={'default'} onClick={handleDeleteFile}>
                <DeleteOutlineIcon />
              </IconButton>
            </Stack>
          )}
        </Stack>
        <Stack pt={2}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label' size='small'>
              Класифікації відповіді
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              size='small'
              disabled={disabled}
              error={error}
              value={variantId}
              label='Класифікації відповіді'
              onChange={(event) => setVariantId(event.target.value)}
            >
              <MenuItem value={0} />
              {valiarnts.map((item) => (
                <MenuItem key={item.absnum} value={item.absnum}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction={'column'}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Коментар
          </Typography>
          <TextareaAutosize
            style={{ minHeight: '80px' }}
            value={comment}
            aria-label='empty textarea'
            onChange={(e) => setComment(e.target.value)}
          />
        </Stack>
        <Stack pt={4} direction={'row'} justifyContent={'space-around'}>
          {!disabled ? (
            <>
              <Button variant={'contained'} color={'error'} onClick={() => handleSaveAndswer(1)}>
                {loading ? <CircularProgress color='inherit' size={SIZE_PROG} /> : 'На затвердження'}
              </Button>
              <Button variant={'contained'} color={'primary'} onClick={() => handleSaveAndswer(0)}>
                {loading ? <CircularProgress color='inherit' size={SIZE_PROG} /> : 'Зберегти'}
              </Button>{' '}
            </>
          ) : null}
          <Button variant={'outlined'} color={'inherit'} onClick={closeHandler}>
            Закрити
          </Button>
        </Stack>

        {errors ? <AlertMessage type='error' message={errors} /> : null}
      </Box>
    </Modal>
  )
}

const WrapperMessageS = styled.span`
  p:not([class]) {
    text-indent: 1.5em;
  }
`
