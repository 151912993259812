import React from 'react'
import Paper from '@mui/material/Paper'
import { Button, Divider, IconButton, Stack, TextField, ThemeProvider, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import { darkTheme } from '../../utils/darkTheme'
import CloseIcon from '@mui/icons-material/Close'
import { MiniChatMessage } from '../MiniChatMeassage'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../store'
import { IStore } from '../../store/types'
import moment from 'moment'
import { createMessageMiniChat, getMiniChatMessages } from '../../store/chatsWritten/actions'
import { EditQuestionModal } from '../Letter/modals/EditQuestionModal'

interface Props {
  closeHandler: () => void
}
export const MiniChat: React.FC<Props> = ({ closeHandler }) => {
  const [val, setVal] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [isScroll, setIsScroll] = React.useState(true)
  const [open, setOpen] = React.useState(false)
  const refMessagesWrapper = React.useRef<HTMLDivElement | null>(null)

  const { chatsWritten } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const theme = useTheme()

  React.useEffect(() => {
    if (refMessagesWrapper.current && isScroll) {
      refMessagesWrapper.current.scrollIntoView()
    }
  }, [refMessagesWrapper, chatsWritten.miniChat, isScroll])

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const element = event.currentTarget
    if (Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 300) {
      !isScroll && setIsScroll(true)
    } else {
      isScroll && setIsScroll(false)
    }
    const dif = element.scrollHeight * 0.2
    if (
      element.scrollTop < dif &&
      !loading &&
      chatsWritten.miniChat &&
      chatsWritten.miniChat.page < chatsWritten.miniChat.pages
    ) {
      const message = chatsWritten.miniChat?.messages[0]
      if (!message) return null
      setLoading(true)
      dispatch(
        getMiniChatMessages({
          message_id: message.message_id,
          part_id: message.part_id,
          page: chatsWritten.miniChat.page + 1,
        })
      ).finally(() => {
        setLoading(false)
      })
    }
  }

  const handleSend = () => {
    if (!chatsWritten.message?.chat_id || !chatsWritten.miniChat || loading || !val) return null
    setLoading(true)
    dispatch(
      createMessageMiniChat({
        chat_id: chatsWritten.message.chat_id,
        message_id: chatsWritten.message.absnum,
        part_id: chatsWritten.message.part_id,
        message: val,
      })
    ).finally(() => {
      setVal('')
      setLoading(false)
    })
  }

  return (
    <Paper sx={{ zIndex: 9, position: 'fixed', width: 500, height: 600, maxHeight: '80vh', bottom: 15, right: 15 }}>
      <Stack direction={'column'} height={'100%'}>
        <ThemeProvider theme={darkTheme}>
          <Stack
            bgcolor={theme.palette.primary.main}
            px={2}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography color={'white'} variant={'h6'}>
              Міні-чат
            </Typography>
            <IconButton onClick={closeHandler}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </ThemeProvider>
        <Stack flex={1} overflow={'auto'} onScroll={handleScroll}>
          {chatsWritten.miniChat?.messages.map((item) => (
            <React.Fragment key={item.absnum}>
              <MiniChatMessage
                name={item.client_fio || item.expert_fio || ''}
                time={moment(item.adate).format('DD.MM.YYYY HH:mm')}
                message={item.message || ''}
                hasCreateBtn={!!item.client_id && !chatsWritten.message?.is_demo}
                createQuestion={() => setOpen(true)}
              />
              <Divider sx={{ py: 1 }} />
            </React.Fragment>
          ))}
          <div ref={refMessagesWrapper} />
        </Stack>
        <Stack p={2}>
          <TextField
            size={'small'}
            fullWidth
            id='outlined-basic'
            multiline
            label='Повідомлення'
            variant='outlined'
            value={val}
            onChange={(e) => {
              setVal(e.target.value)
            }}
          />
          <Stack mt={2} spacing={2} direction={'row'}>
            <Button disabled={loading || !val} variant={'contained'} onClick={handleSend}>
              Відправити
            </Button>
            <Button variant={'outlined'} onClick={closeHandler}>
              Закрити
            </Button>
          </Stack>
        </Stack>
      </Stack>
      {chatsWritten.miniChat?.messages[0] ? (
        <EditQuestionModal
          type='create'
          open={open}
          chatId={chatsWritten.miniChat.messages[0].chat_id}
          closeHandler={() => setOpen(false)}
        />
      ) : null}
    </Paper>
  )
}
