import React from 'react'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Button from '@mui/material/Button'
import { useSearch } from '../../hooks/useSearch'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { SIZE_PROG } from '../../utils/consts'
import { IDtoSearch, IStatSalesState } from '../../store/statSales/types'
import { AsyncThunk } from '@reduxjs/toolkit'
import { BasicDatePicker } from '../../ui/BasicDatePicker'
import moment from 'moment'
import { CalendarOrClockPickerView } from '@mui/x-date-pickers/internals/models'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { IEmployee } from '../../store/employees/types'
import { IStatUnauthorizedState } from '../../store/statUnauthorized/types'

const Styles = makeStyles(() => ({
  searchField: {
    width: '223px !important',
  },
}))

export interface ColumnChats {
  id:
    | 'fio'
    | 'total'
    | 'count_online'
    | 'count_offline'
    | 'hours_online'
    | 'hours_change'
    | 'hours_offline'
    | 'count_transfered'
    | 'interest_transfered'
    | 'count_missed'
    | 'interest_missed'
    | 'avg_time_answer'
    | 'count_initiated'
    | 'over_fifteen_minutes'
  label: string
  minWidth?: number
  align?: 'right' | 'center' | 'left'
  format?: (value: number) => string
}

interface Props {
  columns: ColumnChats[]
  fileName: string
  data: IStatSalesState['data'] | IStatUnauthorizedState['data']
  actionThunk: AsyncThunk<any, any, any>
  types?: { value: string; label: string }[]
  employees?: IEmployee[]
  isDate?: boolean
  isAuthor?: boolean
  downloadFile: (values: IDtoSearch, callback: (data?: Buffer) => void) => void
  handleClearFilters: () => void
}

const views: CalendarOrClockPickerView[] = ['year', 'month', 'day']
const mask = '__/__/____'
const inputFormat = 'dd/MM/yyyy'

export const StatChats: React.FC<Props> = ({
  columns,
  data,
  fileName,
  actionThunk,
  types,
  employees,
  isAuthor,
  isDate,
  downloadFile,
  handleClearFilters,
}) => {
  const [fio, setFio] = React.useState('')
  const [expertFio, setExpertFio] = React.useState('')
  const [from, setFrom] = React.useState<Date | null>(null)
  const [to, setTo] = React.useState<Date | null>(null)
  const [status, setStatus] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const { loading: loadingSearch, handleSearch } = useSearch({ actionThunk })
  const classes = Styles()

  const obj = {
    date_from: from ? moment(from).format('YYYY-MM-DD') : '',
    date_to: to ? moment(to).format('YYYY-MM-DD') : '',
    fio: expertFio || fio,
    status,
  }

  const clearFilters = () => {
    setFio('')
    setExpertFio('')
    setFrom(null)
    setTo(null)
    setStatus('')
    handleClearFilters()
  }

  const downloadXlsx = () => {
    if (loading) return null
    setLoading(true)
    downloadFile(obj, (data) => {
      if (data) {
        const names = [fileName]
        if (isAuthor && expertFio) {
          names.push('_' + expertFio)
        }
        if (isDate && from) {
          names.push(`_від_${moment(from).format('DD/MM/YYYY-HH:mm')}`)
        }
        if (isDate && to) {
          names.push(`_до_${moment(to).format('DD/MM/YYYY-HH:mm')}`)
        }
        const buff = new Uint8Array(data).buffer
        const url = window.URL.createObjectURL(
          new Blob([buff], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${names.join('')}.xlsx`)
        document.body.appendChild(link)
        link.click()
        link.parentNode && link.parentNode.removeChild(link)
      }
      setLoading(false)
    })
  }

  if (!data) {
    return (
      <Box display={'flex'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
        <CircularProgress size={SIZE_PROG} />
      </Box>
    )
  }

  return (
    <Box p={2}>
      <Stack spacing={2} direction={'row'}>
        <Button variant='contained' color='primary' disabled={loading} onClick={downloadXlsx}>
          {loading ? <CircularProgress size={SIZE_PROG} /> : 'Скачати xlsx'}
        </Button>
        <TextField
          className={classes.searchField}
          size={'small'}
          id='outlined-basic-id'
          label='Пошук по ПІБ'
          variant='outlined'
          value={fio}
          onChange={(event) => {
            setExpertFio('')
            setFio(event.target.value)
            handleSearch(event.target.value, 'fio', obj)
          }}
        />
        {employees ? (
          <FormControl>
            <InputLabel size={'small'} id='simple-select'>
              Оператори
            </InputLabel>
            <Select
              className={classes.searchField}
              size={'small'}
              labelId='simple-select'
              id='simple-select'
              label='Оператори'
              value={expertFio}
              onChange={(event) => {
                setFio('')
                setExpertFio(event.target.value)
                handleSearch(event.target.value, 'fio', obj)
              }}
            >
              <MenuItem value={0}>Всі</MenuItem>
              {employees.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.fio}>
                    {item.fio}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        ) : null}
        <BasicDatePicker
          className={classes.searchField}
          id={'from'}
          label='Від'
          value={from}
          views={views}
          mask={mask}
          inputFormat={inputFormat}
          onChange={(date) => {
            setFrom(date)
            handleSearch(date ? moment(date).format('YYYY-MM-DD') : '', 'date_from', obj)
          }}
        />
        <BasicDatePicker
          className={classes.searchField}
          id={'to'}
          label='До'
          value={to}
          views={views}
          mask={mask}
          inputFormat={inputFormat}
          onChange={(date) => {
            setTo(date)
            handleSearch(date ? moment(date).format('YYYY-MM-DD') : '', 'date_to', obj)
          }}
        />
        {types ? (
          <FormControl>
            <InputLabel size={'small'} id='simple-select-status'>
              Тип чату
            </InputLabel>
            <Select
              className={classes.searchField}
              size={'small'}
              labelId='simple-select-status'
              id='simple-select-status'
              label='Тип чату'
              value={status}
              onChange={(event) => {
                setStatus(event.target.value)
                handleSearch(event.target.value, 'status', obj)
              }}
            >
              <MenuItem value={''}>Усі</MenuItem>
              {types.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
        <Button variant='contained' color='primary' onClick={clearFilters}>
          {loadingSearch ? <CircularProgress size={25} /> : 'Очистити фільтр'}
        </Button>
      </Stack>
      <TableContainer sx={{ height: 'calc(100vh - 220px)' }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  sx={{ fontWeight: 'bold' }}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row: any, index) => {
              return (
                <TableRow hover role='checkbox' tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    if (!Object.hasOwn(row, column.id)) return null
                    const value = row[column.id]
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {String(value)}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
