import React from 'react'
import { useSelector } from 'react-redux'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { IStore } from '../../store/types'
import { CHANNELS_ID, Roles, STATUSES_CHAT_SALES } from '../../utils/consts'
import { StatChats, StatChatsRating } from '../../components/Stat'
import { useAppDispatch } from '../../store'
import {
  downlaodStat,
  downlaodStatBlock2,
  downlaodStatBlock3,
  getStat,
  getStatBlock2,
  searchStat,
  searchStatBlock2,
} from '../../store/statSales/actions'
import { ColumnRating } from '../../components/Stat/StatChatsRating'
import { ColumnChats } from '../../components/Stat/StatChats'
import { IDtoSearch } from '../../store/statSales/types'
import { StatSalesActions } from '../../store/statSales'
import { createStatUsing } from '../../store/users/actions'

const columns: ColumnChats[] = [
  { id: 'fio', label: 'ПІБ' },
  { id: 'total', label: 'Загальна к-ть надійшовших чатів, шт', align: 'center' },
  { id: 'count_online', label: 'Кількість опрацьованих чатів онлайн', align: 'center' },
  {
    id: 'count_offline',
    label: 'Кількість опрацьованих чатів оффлайн',
    align: 'center',
  },
  {
    id: 'count_transfered',
    label: 'Кількість переадресованих чатів',
    align: 'center',
  },
  {
    id: 'interest_transfered',
    label: '% переадресованих чатів до опрацьованих',
    align: 'center',
  },
  {
    id: 'count_missed',
    label: 'Кількість пропущених чатів',
    align: 'center',
  },
  {
    id: 'interest_missed',
    label: '% пропущених чатів від загальної кількості надійшовших',
    align: 'center',
  },
  {
    id: 'avg_time_answer',
    label: 'Середня тривалість надання відповіді менеджером ( в хвилинах)',
    align: 'center',
  },
  {
    id: 'hours_online',
    label: 'Кількість годин в онлайні',
    align: 'center',
  },
  {
    id: 'hours_change',
    label: 'Кількість годин в перезмінці',
    align: 'center',
  },
  {
    id: 'hours_offline',
    label: 'Кількість годин в офлайні',
    align: 'center',
  },
]

const columns2: ColumnRating[] = [
  { id: 'title', label: 'Продукт' },
  { id: 'rating_count', label: 'Кількість оцінених чатів', align: 'center' },
  {
    id: 'positive_count',
    label: 'Кількість чатів з позитивною оцінкою (5,4)',
    align: 'center',
  },
  {
    id: 'negative_count',
    label: 'Кількість чатів з негативною оцінкою (3,2,1)',
    align: 'center',
  },
  {
    id: 'without_count',
    label: 'Кількість чатів без оцінки',
    align: 'center',
  },
]

const columns3: ColumnRating[] = [
  { id: 'title', label: 'Продукт' },
  { id: 'rating_interest', label: '% оцінених чатів', align: 'center' },
  {
    id: 'positive_interest',
    label: '% чатів з позитивною оцінкою (5,4)',
    align: 'center',
  },
  {
    id: 'negative_interest',
    label: '% чатів з негативною оцінкою (3,2,1)',
    align: 'center',
  },
  {
    id: 'without_interest',
    label: '% чатів без оцінки',
    align: 'center',
  },
]

export const StatSales: React.FC = () => {
  const [page, setPage] = React.useState(1)
  const { users, statSales, employees } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  if (users.user && users.user.role < Roles.CHIEF) {
    return null
  }

  if (users.user && !users.user.channels.includes(String(CHANNELS_ID.SALES))) {
    return null
  }

  React.useEffect(() => {
    dispatch(getStat({}))
    dispatch(getStatBlock2({}))
  }, [])

  React.useEffect(() => {
    dispatch(createStatUsing({ type_id: CHANNELS_ID.SALES, part_id: page }))
  }, [page])

  React.useEffect(() => {
    if (statSales.data) {
      dispatch(StatSalesActions.setData(statSales.data))
    }

    if (statSales.dataRating) {
      dispatch(StatSalesActions.setDataRating(statSales.dataRating))
    }
  }, [statSales.data, statSales.dataRating, page])

  const dowmloadFileStat = (values: IDtoSearch, callback: (data?: Buffer) => void) => {
    dispatch(downlaodStat(values)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        const payload = res.payload as { data: Buffer }
        callback(payload.data)
      }
      callback()
    })
  }

  const dowmloadFileCount = (values: IDtoSearch, callback: (data?: Buffer) => void) => {
    dispatch(downlaodStatBlock2(values)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        const payload = res.payload as { data: Buffer }
        callback(payload.data)
      }
      callback()
    })
  }

  const dowmloadFileInterest = (values: IDtoSearch, callback: (data?: Buffer) => void) => {
    dispatch(downlaodStatBlock3(values)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        const payload = res.payload as { data: Buffer }
        callback(payload.data)
      }
      callback()
    })
  }

  const resetDataStat = () => {
    if (statSales.data) {
      dispatch(StatSalesActions.setData(statSales.data))
    }
  }

  const resetDataStatRating = () => {
    if (statSales.dataRating) {
      dispatch(StatSalesActions.setDataRating(statSales.dataRating))
    }
  }

  const renderPage = () => {
    if (page === 1) {
      return (
        <StatChats
          fileName='Звіт_чати'
          columns={columns}
          data={statSales.searchData}
          downloadFile={dowmloadFileStat}
          actionThunk={searchStat}
          handleClearFilters={resetDataStat}
          types={Object.keys(STATUSES_CHAT_SALES).map((key) => ({ value: key, label: STATUSES_CHAT_SALES[key].title }))}
          employees={employees.data?.employees.filter((user) => user.channels?.includes(String(CHANNELS_ID.SALES)))}
        />
      )
    }
    if (page === 2) {
      return (
        <StatChatsRating
          id={page}
          fileName='Звіт_продукти'
          dataRating={statSales.searchDataRating}
          columns={columns2}
          downloadFile={dowmloadFileCount}
          actionThunk={searchStatBlock2}
          handleClearFilters={resetDataStatRating}
          types={Object.keys(STATUSES_CHAT_SALES).map((key) => ({ value: key, label: STATUSES_CHAT_SALES[key].title }))}
          employees={employees.data?.employees.filter((user) => user.channels?.includes(String(CHANNELS_ID.SALES)))}
        />
      )
    }
    if (page === 3) {
      return (
        <StatChatsRating
          id={page}
          fileName='Статистика_блок_3'
          dataRating={statSales.searchDataRating}
          columns={columns3}
          downloadFile={dowmloadFileInterest}
          actionThunk={searchStatBlock2}
          handleClearFilters={resetDataStatRating}
          types={Object.keys(STATUSES_CHAT_SALES).map((key) => ({ value: key, label: STATUSES_CHAT_SALES[key].title }))}
          employees={employees.data?.employees.filter((user) => user.channels?.includes(String(CHANNELS_ID.SALES)))}
        />
      )
    }
  }

  return (
    <Paper sx={{ m: 2 }} style={{ minHeight: 'calc(100vh - 96px)' }}>
      <Stack pt={2} pl={2} direction={'row'}>
        <Button
          variant='outlined'
          color='primary'
          disabled={page === 1}
          onClick={() => setPage(1)}
          style={{ height: '36px', marginRight: '10px' }}
        >
          Звіт – чати
        </Button>
        <Button
          variant='outlined'
          color='primary'
          disabled={page === 2}
          onClick={() => setPage(2)}
          style={{ height: '36px', marginRight: '10px' }}
        >
          Звіт – продукти
        </Button>
        <Button
          variant='outlined'
          color='primary'
          disabled={page === 3}
          onClick={() => setPage(3)}
          style={{ height: '36px' }}
        >
          Блок 3
        </Button>
      </Stack>
      {renderPage()}
    </Paper>
  )
}
