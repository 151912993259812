import React from 'react'
import { useSelector } from 'react-redux'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { IStore } from '../../store/types'
import { CHANNELS_ID, Roles, STATUSES_CHAT_UNAUTHORIZED } from '../../utils/consts'
import { StatChats } from '../../components/Stat'
import { useAppDispatch } from '../../store'
import {
  downlaodStat,
  downlaodStatBlock2,
  downlaodStatBlock3,
  downlaodStatBlock4,
  getStat,
  getStatBlock2,
  getStatBlock4,
  searchStat,
  searchStatBlock2,
  searchStatBlock4,
} from '../../store/statUnauthorized/actions'
import { ColumnChats } from '../../components/Stat/StatChats'
import { IDtoSearch } from '../../store/statUnauthorized/types'
import { StatUnauthorizedActions } from '../../store/statUnauthorized'
import { ColumnRating, StatChatsRating } from '../../components/Stat/StatChatsRating'
import { createStatUsing } from '../../store/users/actions'

const columns: ColumnChats[] = [
  { id: 'fio', label: 'ПІБ' },
  { id: 'total', label: 'Усі чати', align: 'center' },
  {
    id: 'count_transfered',
    label: 'Передані у ВТМ',
    align: 'center',
  },
  {
    id: 'interest_transfered',
    label: '% переданих чатів',
    align: 'center',
  },
  {
    id: 'avg_time_answer',
    label: 'Середній час до розподілу в хв',
    align: 'center',
  },
  {
    id: 'over_fifteen_minutes',
    label: '% чатів із розподілом понад 15 хв',
    align: 'center',
  },
]

const columns2: ColumnRating[] = [
  { id: 'title', label: 'Продукт' },
  { id: 'rating_count', label: 'Кількість оцінених чатів', align: 'center' },
  {
    id: 'positive_count',
    label: 'Кількість чатів з позитивною оцінкою (5,4)',
    align: 'center',
  },
  {
    id: 'negative_count',
    label: 'Кількість чатів з негативною оцінкою (3,2,1)',
    align: 'center',
  },
  {
    id: 'without_count',
    label: 'Кількість чатів без оцінки',
    align: 'center',
  },
]

const columns3: ColumnRating[] = [
  { id: 'title', label: 'Продукт' },
  { id: 'rating_interest', label: '% оцінених чатів', align: 'center' },
  {
    id: 'positive_interest',
    label: '% чатів з позитивною оцінкою (5,4)',
    align: 'center',
  },
  {
    id: 'negative_interest',
    label: '% чатів з негативною оцінкою (3,2,1)',
    align: 'center',
  },
  {
    id: 'without_interest',
    label: '% чатів без оцінки',
    align: 'center',
  },
]

const columns4: ColumnRating[] = [
  { id: 'date_key', label: 'Час' },
  { id: 'created_count', label: 'Чатів створено', align: 'center' },
  {
    id: 'distributed_count',
    label: 'Чатів взято в роботу',
    align: 'center',
  },
]

export const StatUnauthorized: React.FC = () => {
  const [page, setPage] = React.useState(1)
  const { users, statUnauthorized, employees } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  if (users.user && users.user.role < Roles.CHIEF) {
    return null
  }

  if (users.user && !users.user.channels.includes(String(CHANNELS_ID.UNAUTHORIZED))) {
    return null
  }

  React.useEffect(() => {
    dispatch(getStat({}))
    dispatch(getStatBlock2({}))
    dispatch(getStatBlock4({}))
  }, [])

  React.useEffect(() => {
    dispatch(createStatUsing({ type_id: CHANNELS_ID.UNAUTHORIZED, part_id: page }))
  }, [page])

  React.useEffect(() => {
    if (statUnauthorized.data) {
      dispatch(StatUnauthorizedActions.setData(statUnauthorized.data))
    }
  }, [statUnauthorized.data])

  const dowmloadFileStat = (values: IDtoSearch, callback: (data?: Buffer) => void) => {
    dispatch(downlaodStat(values)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        const payload = res.payload as { data: Buffer }
        callback(payload.data)
      }
      callback()
    })
  }

  const dowmloadFileCount = (values: IDtoSearch, callback: (data?: Buffer) => void) => {
    dispatch(downlaodStatBlock2(values)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        const payload = res.payload as { data: Buffer }
        callback(payload.data)
      }
      callback()
    })
  }

  const dowmloadFileInterest = (values: IDtoSearch, callback: (data?: Buffer) => void) => {
    dispatch(downlaodStatBlock3(values)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        const payload = res.payload as { data: Buffer }
        callback(payload.data)
      }
      callback()
    })
  }

  const dowmloadStatChats = (values: IDtoSearch, callback: (data?: Buffer) => void) => {
    dispatch(downlaodStatBlock4(values)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        const payload = res.payload as { data: Buffer }
        callback(payload.data)
      }
      callback()
    })
  }

  const resetDataStat = () => {
    if (statUnauthorized.data) {
      dispatch(StatUnauthorizedActions.setData(statUnauthorized.data))
    }
  }

  const resetDataStatRating = () => {
    if (statUnauthorized.dataRating) {
      dispatch(StatUnauthorizedActions.setDataRating(statUnauthorized.dataRating))
    }
  }

  const resetStatChats = () => {
    if (statUnauthorized.statChats) {
      dispatch(StatUnauthorizedActions.setStatChats(statUnauthorized.statChats))
    }
  }

  const renderPage = () => {
    if (page === 1) {
      return (
        <StatChats
          isAuthor
          isDate
          fileName='Звіт_чати'
          columns={columns}
          data={statUnauthorized.searchData}
          downloadFile={dowmloadFileStat}
          actionThunk={searchStat}
          handleClearFilters={resetDataStat}
          types={Object.keys(STATUSES_CHAT_UNAUTHORIZED).map((key) => ({
            value: key,
            label: STATUSES_CHAT_UNAUTHORIZED[key].title,
          }))}
          employees={employees.data?.employees.filter((user) =>
            user.channels?.includes(String(CHANNELS_ID.UNAUTHORIZED))
          )}
        />
      )
    }
    if (page === 2) {
      return (
        <StatChatsRating
          isAuthor
          isDate
          id={page}
          fileName='Звіт_продукти'
          dataRating={statUnauthorized.searchDataRating}
          columns={columns2}
          downloadFile={dowmloadFileCount}
          actionThunk={searchStatBlock2}
          handleClearFilters={resetDataStatRating}
          types={Object.keys(STATUSES_CHAT_UNAUTHORIZED).map((key) => ({
            value: key,
            label: STATUSES_CHAT_UNAUTHORIZED[key].title,
          }))}
          employees={employees.data?.employees.filter((user) =>
            user.channels?.includes(String(CHANNELS_ID.UNAUTHORIZED))
          )}
        />
      )
    }
    if (page === 3) {
      return (
        <StatChatsRating
          isAuthor
          isDate
          id={page}
          fileName='Статистика_блок_3'
          dataRating={statUnauthorized.searchDataRating}
          columns={columns3}
          downloadFile={dowmloadFileInterest}
          actionThunk={searchStatBlock2}
          handleClearFilters={resetDataStatRating}
          types={Object.keys(STATUSES_CHAT_UNAUTHORIZED).map((key) => ({
            value: key,
            label: STATUSES_CHAT_UNAUTHORIZED[key].title,
          }))}
          employees={employees.data?.employees.filter((user) =>
            user.channels?.includes(String(CHANNELS_ID.UNAUTHORIZED))
          )}
        />
      )
    }
    if (page === 4) {
      return (
        <StatChatsRating
          isAuthor
          isDate
          id={page}
          fileName='Звіт_по_чатам'
          dataRating={statUnauthorized.searchStatChats}
          columns={columns4}
          downloadFile={dowmloadStatChats}
          actionThunk={searchStatBlock4}
          handleClearFilters={resetStatChats}
          types={Object.keys(STATUSES_CHAT_UNAUTHORIZED).map((key) => ({
            value: key,
            label: STATUSES_CHAT_UNAUTHORIZED[key].title,
          }))}
          employees={employees.data?.employees.filter((user) =>
            user.channels?.includes(String(CHANNELS_ID.UNAUTHORIZED))
          )}
        />
      )
    }
  }

  return (
    <Paper sx={{ m: 2 }} style={{ minHeight: 'calc(100vh - 96px)' }}>
      <Stack pt={2} pl={2} direction={'row'}>
        <Button
          variant='outlined'
          color='primary'
          disabled={page === 1}
          onClick={() => setPage(1)}
          style={{ height: '36px', marginRight: '10px' }}
        >
          Звіт – чати
        </Button>
        <Button
          variant='outlined'
          color='primary'
          disabled={page === 2}
          onClick={() => setPage(2)}
          style={{ height: '36px', marginRight: '10px' }}
        >
          Звіт – продукти
        </Button>
        <Button
          variant='outlined'
          color='primary'
          disabled={page === 3}
          onClick={() => setPage(3)}
          style={{ height: '36px', marginRight: '10px' }}
        >
          Блок 3
        </Button>
        <Button
          variant='outlined'
          color='primary'
          disabled={page === 4}
          onClick={() => setPage(4)}
          style={{ height: '36px' }}
        >
          Звіт по чатам
        </Button>
      </Stack>
      {renderPage()}
    </Paper>
  )
}
