import React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { Button, CircularProgress, IconButton, Link, Pagination, Rating, Stack, useTheme } from '@mui/material'
import { MiniChat } from '../MiniChat'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import moment from 'moment'
import {
  CHANNELS_ID,
  RightSidebarType,
  Roles,
  SIZE_PROG,
  STATUSES_WRITTEN,
  WrittenStatus,
  WrittenType,
} from '../../utils/consts'
import { useAppDispatch } from '../../store'
import {
  changeStatus,
  deleteWittenMessage,
  getMiniChat,
  getTemplate,
  getWittenMessage,
  publicAnswer,
} from '../../store/chatsWritten/actions'
import { ChatsWrittenActions } from '../../store/chatsWritten'
import { AnswerModal } from './modals/AnswerModal'
import { ChooseExpertModal } from './modals/ChooseExpertModal'
import { IMessageWritten } from '../../store/chatsWritten/types'
import { ConfirmModal } from '../modals/ConfirmModal'
import { RefusalModal } from './modals/RefusalModal'
import { ClarifyModal } from './modals/ClarifyModal'
import { SplitModal } from './modals/SplitModal'
import SearchIcon from '@mui/icons-material/Search'
import EditIcon from '@mui/icons-material/Edit'
import { TableHeadWritten } from './TableHeadWritten'
import { TopicMessageModal } from './modals/TopicMessageModal'
import { parseLink } from '../../utils/parseLink'
import { EditQuestionModal } from './modals/EditQuestionModal'
import { WarningModal } from './modals/WarningModal'
import { BadgeCount } from '../Dadge'
import styled from 'styled-components'
import { Box } from '@mui/material'
import { HistoryModal } from './modals/HistoryModal'
import { setRightSidebar } from '../../store/rightSidebarSlice'
import { changeMessge } from '../../utils/changeMessage'

const STATUS_NAME: any = {
  3: { name: 'Зняти з уточнення', status: WrittenStatus.NEW_CLARIFIED },
  4: { name: 'Зняти з уточнення', status: WrittenStatus.NEW_CLARIFIED },
  6: { name: 'Відправити на доопрацювання', status: WrittenStatus.REPID },
  7: { name: 'Повернути на затвердження', status: WrittenStatus.REPID },
}

type TypeData =
  | 'delete'
  | 'refusal'
  | 'choose'
  | 'clarify'
  | 'split'
  | 'review'
  | 'edit'
  | 'topic'
  | 'question'
  | 'minichat'
  | 'history'
  | 'transfer'
  | null

export const Letter: React.FC = () => {
  const [search, setSearch] = React.useState('')
  const [typeData, setTypeData] = React.useState<TypeData>(null)
  const [idScroll, setIdScroll] = React.useState(0)

  const ref = React.useRef<Record<string, any>>({})

  const { chatsWritten, users } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const theme = useTheme()

  React.useEffect(() => {
    if (idScroll && ref.current[idScroll]) {
      ref.current[idScroll].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
      setIdScroll(0)
    }
  }, [idScroll, chatsWritten.data])

  const handleGetMessage = (data: { message: IMessageWritten; type: TypeData }) => {
    dispatch(getWittenMessage({ id: data.message.absnum, partId: data.message.part_id }))
    setTypeData(data.type)
  }

  const handleDelete = () => {
    if (!chatsWritten.message) return null
    dispatch(deleteWittenMessage({ id: chatsWritten.message.absnum, partId: chatsWritten.message.part_id }))
    setTypeData(null)
  }

  const checkColor = (message: IMessageWritten) => {
    if (message.status > WrittenStatus.ON_APPROVAL) return ''
    if (!message.deadline || !message.adate) return ''
    const createdHorhs = (new Date().getTime() - new Date(message.adate).getTime()) / 1000 / 60 / 60
    if (createdHorhs < 1 && message.status === WrittenStatus.NEW) return '#e1f5fe'

    const hours = (new Date(message.deadline).getTime() - new Date().getTime()) / 1000 / 60 / 60
    if (hours < 5) return '#ffebee'
    if (hours < 8) return '#fff3e0'
    return ''
  }

  const handleTamplate = (message: IMessageWritten) => {
    dispatch(getTemplate({ id: message.absnum, part_id: message.part_id })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        const filename = message.part_id ? `${message.absnum}-${message.part_id}.docx` : `${message.absnum}.docx`
        const { data } = res.payload as { data: Buffer }
        const buff = new Uint8Array(data).buffer
        const blobUrl = URL.createObjectURL(
          new Blob([buff], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
        )

        const link = document.createElement('a')
        link.download = filename
        link.href = blobUrl

        document.body.appendChild(link)
        link.click()

        setTimeout(() => {
          link.remove()
          window.URL.revokeObjectURL(blobUrl)
        }, 0)
      }
    })
  }

  const handleOpenMiniChat = (message: IMessageWritten) => {
    handleGetMessage({ message, type: null })
    dispatch(getMiniChat({ message_id: message.absnum, part_id: message.part_id }))
  }

  const buttons: any = {
    clarify: (message: IMessageWritten) => (
      <Button key={'clarify'} onClick={() => handleGetMessage({ message, type: 'clarify' })}>
        Уточнити
      </Button>
    ),
    split: (message: IMessageWritten) => (
      <Button key={'split'} onClick={() => handleGetMessage({ message, type: 'split' })}>
        Розбити
      </Button>
    ),
    refusal: (message: IMessageWritten) => (
      <Button key={'refusal'} onClick={() => handleGetMessage({ message, type: 'refusal' })}>
        Відмовити
      </Button>
    ),
    choose: (message: IMessageWritten) => (
      <Button key={'choose'} onClick={() => handleGetMessage({ message, type: 'choose' })}>
        {[WrittenStatus.NEW, WrittenStatus.NEW_CLARIFIED].includes(message.status)
          ? 'Призначити експерта'
          : 'Змінити експерта'}
      </Button>
    ),
    link: (message: IMessageWritten) => (
      <Link key={'link'} onClick={() => handleTamplate(message)} underline='hover' style={{ cursor: 'pointer' }}>
        Сформувати файл відповіді
      </Link>
    ),
    answer: (message: IMessageWritten) => (
      <Button key={'answer'} onClick={() => handleGetMessage({ message, type: 'edit' })}>
        Надіслати відповідь
      </Button>
    ),
    delete: (message: IMessageWritten) => (
      <Button key={'delete'} onClick={() => handleGetMessage({ message, type: 'delete' })}>
        Видалити
      </Button>
    ),
    changeStatus: (message: IMessageWritten) => (
      <Button
        key={'changeStatus'}
        onClick={() =>
          dispatch(
            changeStatus({
              message_id: message.absnum,
              part_id: message.part_id,
              status: STATUS_NAME[message.status].status,
            })
          )
        }
      >
        {STATUS_NAME[message.status].name}
      </Button>
    ),
    review: (message: IMessageWritten) => (
      <Stack key={'review'} direction={'row'} alignItems={'center'}>
        Відповідь
        <IconButton onClick={() => handleGetMessage({ message, type: 'review' })}>
          <SearchIcon />
        </IconButton>
        {message.status <= WrittenStatus.ON_APPROVAL ? (
          <IconButton onClick={() => handleGetMessage({ message, type: 'edit' })}>
            <EditIcon />
          </IconButton>
        ) : null}
      </Stack>
    ),
    send: (message: IMessageWritten) => (
      <Button key={'send'} onClick={() => dispatch(publicAnswer({ id: message.absnum, part_id: message.part_id }))}>
        Затвердити і надіслати
      </Button>
    ),
    transfer: (message: IMessageWritten) => (
      <Button
        key={'transfer'}
        onClick={() => {
          setTypeData('transfer')
          dispatch(ChatsWrittenActions.setMessage(message))
          dispatch(setRightSidebar(RightSidebarType.WRITTEN))
        }}
      >
        Передати
      </Button>
    ),
  }

  const renderMenu = (message: IMessageWritten) => {
    if (
      users.user &&
      [Roles.CONSULTANT, Roles.TRAINEE].includes(users.user.role) &&
      message.expert_id !== users.user.id &&
      message.status > WrittenStatus.NEW_CLARIFIED &&
      message.status < WrittenStatus.RATED_BY_CLIENT
    )
      return (
        <>
          {WrittenStatus.ON_APPROVAL <= message.status ? (
            <Stack key={'review'} direction={'row'} alignItems={'center'}>
              Відповідь
              <IconButton onClick={() => handleGetMessage({ message, type: 'review' })}>
                <SearchIcon />
              </IconButton>
            </Stack>
          ) : null}
          <Button key={'choose'} onClick={() => handleGetMessage({ message, type: 'choose' })}>
            Змінити експерта
          </Button>
        </>
      )

    switch (message.status) {
      case WrittenStatus.NEW:
        return ['refusal', 'split', 'choose', 'link', 'answer', 'transfer', 'delete'].map((type: string) =>
          buttons[type](message)
        )
      case WrittenStatus.TRANSFER_TO_EXPERT:
        return ['refusal', 'choose', 'clarify', 'split', 'link', 'answer', 'transfer', 'delete'].map((type: string) =>
          buttons[type](message)
        )
      case WrittenStatus.CLARIFIED:
        return ['choose', 'changeStatus', 'split', 'link', 'answer', 'transfer', 'delete'].map((type: string) =>
          buttons[type](message)
        )
      case WrittenStatus.RECIVED_CLARIFIED:
        return ['choose', 'changeStatus', 'split', 'link', 'answer', 'transfer', 'delete'].map((type: string) =>
          buttons[type](message)
        )
      case WrittenStatus.NEW_CLARIFIED:
        return ['refusal', 'split', 'choose', 'link', 'answer', 'transfer', 'delete'].map((type: string) =>
          buttons[type](message)
        )
      case WrittenStatus.ON_APPROVAL:
        return ['review', 'choose', 'changeStatus', 'send'].map((type: string) => buttons[type](message))
      case WrittenStatus.REPID:
        return ['review', 'choose', 'send'].map((type: string) => buttons[type](message))
      case WrittenStatus.SEND_TO_CLIENT:
        return ['review', 'choose', 'changeStatus'].map((type: string) => buttons[type](message))
      case WrittenStatus.TECH_SUPPORT:
        return ['review'].map((type: string) => buttons[type](message))
      case WrittenStatus.RATED_BY_CLIENT:
      case WrittenStatus.CLOSING_IS_POSTPONED:
      case WrittenStatus.CLOSED:
        return ['review'].map((type: string) => buttons[type](message))
      default:
        return null
    }
  }

  const renderModal = () => {
    if (!chatsWritten.message || !typeData) return null
    if (
      !['history', 'transfer', 'split', 'topic', 'choose', 'question'].includes(typeData) &&
      !chatsWritten.message.expert_id
    )
      return (
        <WarningModal
          title='Призначте експерта!'
          open
          closeHandler={() => dispatch(ChatsWrittenActions.setMessage(null))}
        />
      )
    if (
      !['history', 'transfer', 'split', 'topic', 'choose', 'question'].includes(typeData) &&
      !chatsWritten.message.topic
    )
      return (
        <WarningModal
          title='Спочатку заповніть тему!'
          open
          closeHandler={() => dispatch(ChatsWrittenActions.setMessage(null))}
        />
      )
    switch (typeData) {
      case 'delete':
        return (
          <ConfirmModal
            title={`Видалити №${chatsWritten.message.absnum}${
              chatsWritten.message.part_id ? `-${chatsWritten.message.part_id}` : ''
            }`}
            open={!!chatsWritten.message}
            delHandler={handleDelete}
            closeHandler={() => dispatch(ChatsWrittenActions.setMessage(null))}
          />
        )
      case 'choose':
        return (
          <ChooseExpertModal
            open={!!chatsWritten.message}
            message={chatsWritten.message}
            closeHandler={() => dispatch(ChatsWrittenActions.setMessage(null))}
          />
        )
      case 'refusal':
        return (
          <RefusalModal
            open={!!chatsWritten.message}
            message={chatsWritten.message}
            closeHandler={() => dispatch(ChatsWrittenActions.setMessage(null))}
          />
        )
      case 'clarify':
        return (
          <ClarifyModal
            open={!!chatsWritten.message}
            message={chatsWritten.message}
            closeHandler={() => dispatch(ChatsWrittenActions.setMessage(null))}
          />
        )
      case 'split':
        return (
          <SplitModal
            open={!!chatsWritten.message}
            message={chatsWritten.message}
            closeHandler={() => dispatch(ChatsWrittenActions.setMessage(null))}
          />
        )
      case 'review':
        return (
          <AnswerModal
            disabled
            open={!!chatsWritten.message}
            message={chatsWritten.message}
            closeHandler={() => dispatch(ChatsWrittenActions.setMessage(null))}
          />
        )
      case 'edit':
        return (
          <AnswerModal
            open={!!chatsWritten.message}
            message={chatsWritten.message}
            closeHandler={() => dispatch(ChatsWrittenActions.setMessage(null))}
          />
        )
      case 'topic':
        return (
          <TopicMessageModal
            open={!!chatsWritten.message}
            message={chatsWritten.message}
            closeHandler={() => dispatch(ChatsWrittenActions.setMessage(null))}
          />
        )
      case 'question':
        return (
          <EditQuestionModal
            type='edit'
            open={!!chatsWritten.message}
            message={chatsWritten.message}
            closeHandler={() => {
              setIdScroll(chatsWritten.message?.absnum || 0)
              dispatch(ChatsWrittenActions.setMessage(null))
            }}
          />
        )
      case 'history':
        return (
          <HistoryModal
            open={!!chatsWritten.message}
            messageId={chatsWritten.message.absnum}
            partId={chatsWritten.message.part_id}
            closeHandler={() => dispatch(ChatsWrittenActions.setMessage(null))}
          />
        )
      default:
        return null
    }
  }

  const renderQuestion = (message: IMessageWritten) => {
    let href = ''
    let name = ''

    const div = document.createElement('div')
    div.innerHTML = parseLink(message.question)

    div.querySelectorAll('a').forEach((value) => {
      const _href = value.getAttribute('href') || ''
      if (_href.includes('/attachments')) {
        href = _href
        name = value.innerHTML || ''
      }
    })

    return (
      <>
        <WrapperMessageS
          style={{ cursor: 'pointer' }}
          onClick={() => handleGetMessage({ message, type: 'question' })}
          dangerouslySetInnerHTML={{
            __html: changeMessge(div.innerHTML, search),
          }}
        />
        {href ? (
          <p>
            {' '}
            <a href={href}>{name || href}</a>
          </p>
        ) : null}
      </>
    )
  }

  if (!users.user?.channels.includes(String(CHANNELS_ID.WRITTEN))) return null

  return (
    <>
      <Paper sx={{ m: 2, overflow: 'hidden' }}>
        <TableContainer sx={{ height: 'calc(100vh - 150px)' }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHeadWritten search={search} handleChangeSearch={setSearch} />
            <TableBody>
              {chatsWritten.data?.messages.map((row, index) => (
                <TableRow
                  key={`${row.absnum}_${row.part_id}_${index}`}
                  hover
                  role='checkbox'
                  tabIndex={-1}
                  style={{ background: checkColor(row) }}
                  ref={(el) => (ref.current[row.absnum] = el)}
                >
                  <TableCell align={'center'}>
                    {row.is_first ? <Typography color={'green'}>Перше</Typography> : null}
                    <Typography fontWeight={'bold'}>
                      {row.part_id ? `${row.absnum} / ${row.part_id}` : row.absnum}
                    </Typography>
                    <Typography color={'green'}>
                      {row.count} / {row.avg} / {row.count_months}
                    </Typography>
                    <Typography>{moment(row.adate).format('DD.MM.YYYY HH:mm')}</Typography>
                    {WrittenType[row.type_id as number] ? (
                      <Typography fontSize={14}>{WrittenType[row.type_id as number]}</Typography>
                    ) : (
                      <Link href={row.site_url}>Сайт</Link>
                    )}
                  </TableCell>
                  <TableCell align={'center'}>
                    <Typography>{row.deadline ? moment(row.deadline).format('DD.MM.YYYY HH:mm') : ''}</Typography>
                    <hr />
                    <Link
                      sx={{ color: 'crosshair' }}
                      onClick={() => handleGetMessage({ message: row, type: 'history' })}
                    >
                      {moment(row.edited_at).format('DD.MM.YYYY HH:mm')}
                    </Link>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Link href={row.email}>{row.email}</Link>
                    {row.client_fio?.split(' ').map((name, idx) => (
                      <Typography key={`${name}_${idx}`}>{name}</Typography>
                    ))}
                    {row.phone ? <Link href={row.phone}>{row.phone}</Link> : null}
                    <br />
                    <br />
                    {row.company ? <Typography fontSize={14}>Організація: {row.company}</Typography> : null}
                  </TableCell>
                  <TableCell align={'left'} sx={{ maxWidth: 200 }}>
                    <div style={{ textAlign: 'center' }}>
                      <Link
                        onClick={() => handleGetMessage({ message: row, type: 'topic' })}
                        fontSize={14}
                        fontWeight={'bold'}
                        fontStyle={'italic'}
                        style={{ cursor: 'pointer' }}
                      >
                        {row.topic || 'Тема'}
                      </Link>
                    </div>

                    <hr />

                    {row.is_added ? (
                      <>
                        <Typography
                          align='center'
                          color={'#b71c1c'}
                          sx={{ fontStyle: 'italic', cursor: 'pointer' }}
                          onClick={() => handleGetMessage({ message: row, type: 'question' })}
                        >
                          Додано уточнення!
                        </Typography>
                        <hr />
                      </>
                    ) : null}

                    <div>{renderQuestion(row)}</div>

                    {row.update_question ? (
                      <Typography mt={2} fontSize={12} textAlign={'right'} color={theme.palette.primary.main}>
                        Останнє редагування:
                        <br />
                        {moment(row.update_question).format('DD.MM.YYYY HH:mm')}
                      </Typography>
                    ) : null}
                  </TableCell>
                  <TableCell align={'center'}>
                    <Typography fontSize={14}>{row.product_name}</Typography>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Typography fontSize={14}>
                      {row.role === Roles.CHIEF ? `${row.expert_fio}, керівник` : row.expert_fio}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ maxWidth: 150 }} align={'center'}>
                    <Typography fontSize={12} color={STATUSES_WRITTEN[row.status || 0]?.color}>
                      {STATUSES_WRITTEN[row.status || 0]?.title}
                    </Typography>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Stack direction={'column'} alignItems={'center'}>
                      {row.status !== WrittenStatus.NEW ? (
                        <Stack direction={'row'}>
                          <Button onClick={() => handleOpenMiniChat(row)}>Міні-чат</Button>
                          {row.count_not_read ? <BadgeCount count={row.count_not_read} /> : null}
                        </Stack>
                      ) : null}
                      {renderMenu(row)}
                      {row.rating ? <Rating name='read-only' value={row.rating} readOnly size={'small'} /> : null}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <WrapperS>
          <WrapperAllS>
            <div>Всього: {chatsWritten.data?.total || 0}</div>
            <div style={{ width: '25px', height: '25px' }}>
              {chatsWritten.loading ? <CircularProgress size={SIZE_PROG} /> : null}
            </div>
          </WrapperAllS>
          <Pagination
            style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 0' }}
            count={chatsWritten.data ? chatsWritten.data.pages : 1}
            page={chatsWritten.data ? chatsWritten.data.page : 1}
            onChange={(e, page) => dispatch(ChatsWrittenActions.changePage(page))}
          />
        </WrapperS>
      </Paper>
      {renderModal()}
      {chatsWritten.miniChat && <MiniChat closeHandler={() => dispatch(ChatsWrittenActions.setMiniChat(null))} />}
    </>
  )
}

const WrapperS = styled(Box)`
  position: relative;
`
const WrapperAllS = styled(Box)`
  position: absolute;
  right: 400px;
  bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
`
const WrapperMessageS = styled.span`
  p:not([class]) {
    text-indent: 1.5em;
  }
`
