import React from 'react'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Button from '@mui/material/Button'
import { useSearch } from '../../hooks/useSearch'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import { SIZE_PROG } from '../../utils/consts'
import { IDtoSearch } from '../../store/statSales/types'
import { AsyncThunk } from '@reduxjs/toolkit'
import { BasicDatePicker } from '../../ui/BasicDatePicker'
import moment from 'moment'
import { FormControl, InputLabel, Link, MenuItem, Pagination, Select, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { SelectSearch } from '../../ui/SelectSearch'
import { SelectCheckboxSearch } from '../../ui/SelectCheckboxSearch'

const Styles = makeStyles(() => ({
  searchField: {
    width: '223px !important',
  },
}))

export interface ColumnChats {
  id: string
  label: string
  minWidth?: number
  align?: 'right' | 'center' | 'left'
  format?: (value: number) => string
}

export interface ColumnChats {
  id: string
  label: string
  minWidth?: number
  align?: 'right' | 'center' | 'left'
  format?: (value: number) => string
}

interface Props {
  filterTypes?: { value: string | number; label: string }[]
  id: number
  columns: ColumnChats[]
  fileName: string
  labelExpert?: string
  total?: number
  isEmail?: boolean
  data?: any[] | null
  siteIds?: { value: string; label: string }[]
  experts?: { value: string; label: string }[]
  dataPage?: { page: number; pages: number } | null
  actionThunk: AsyncThunk<any, any, any>
  downloadFile: (values: IDtoSearch, callback: (data?: Buffer) => void) => void
  handleClearFilters?: () => void
}

const formatDate = 'YYYY-MM-DD HH:mm'

export const StatDataPages: React.FC<Props> = ({
  id,
  columns,
  data,
  total,
  fileName,
  actionThunk,
  dataPage,
  siteIds,
  filterTypes,
  experts,
  labelExpert = 'Консультант',
  isEmail,
  downloadFile,
  handleClearFilters,
}) => {
  const [siteId, setSiteId] = React.useState<string[]>([])
  const [expert_id, setExpertId] = React.useState('')
  const [from, setFrom] = React.useState<Date | null>(null)
  const [to, setTo] = React.useState<Date | null>(null)
  const [type, setType] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const { loading: loadingSearch, handleSearch } = useSearch({ actionThunk })
  const classes = Styles()

  const onSearch = () => {
    const obj = {
      site_id:
        siteIds
          ?.filter((it) => siteId.includes(it.label))
          .map((it) => it.value)
          .join(',') || '',
      expert_id,
      date_from: from ? moment(from).format(formatDate) : '',
      date_to: to ? moment(to).format(formatDate) : '',
      type,
      email,
    }
    handleSearch('', '', obj)
  }

  const clearFilters = () => {
    setFrom(null)
    setTo(null)
    setSiteId([])
    setExpertId('')
    setEmail('')
  }

  React.useEffect(() => {
    clearFilters()
  }, [id])

  const downloadXlsx = () => {
    if (loading) return null
    setLoading(true)
    const obj = {
      site_id:
        siteIds
          ?.filter((it) => siteId.includes(it.label))
          .map((it) => it.value)
          .join(',') || '',
      expert_id,
      date_from: from ? moment(from).format(formatDate) : '',
      date_to: to ? moment(to).format(formatDate) : '',
      type,
      email,
    }

    downloadFile(obj, (data) => {
      if (data) {
        const names = [fileName]
        if (from) {
          names.push(`_від_${moment(from).format('DD/MM/YYYY-HH:mm')}`)
        }
        if (to) {
          names.push(`_до_${moment(to).format('DD/MM/YYYY-HH:mm')}`)
        }
        const buff = new Uint8Array(data).buffer
        const url = window.URL.createObjectURL(
          new Blob([buff], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${names.join('')}.xlsx`)
        document.body.appendChild(link)
        link.click()
        link.parentNode && link.parentNode.removeChild(link)
      }
      setLoading(false)
    })
  }

  const handleChangePage = (event: unknown, page: number) => {
    if (!dataPage) return null
    if (dataPage.pages >= page) {
      const obj = {
        site_id:
          siteIds
            ?.filter((it) => siteId.includes(it.label))
            .map((it) => it.value)
            .join(',') || '',
        expert_id,
        date_from: from ? moment(from).format(formatDate) : '',
        date_to: to ? moment(to).format(formatDate) : '',
        type,
      }

      handleSearch(String(page), 'page', obj)
    }
  }

  if (!data) {
    return (
      <Box display={'flex'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
        <CircularProgress size={SIZE_PROG} />
      </Box>
    )
  }

  return (
    <Box p={2}>
      <Stack direction={'row'} flexWrap={'wrap'} gap={2}>
        <Button variant='contained' color='primary' disabled={loading} onClick={downloadXlsx}>
          {loading ? <CircularProgress size={SIZE_PROG} /> : 'Скачати звіт'}
        </Button>
        {siteIds ? (
          <SelectCheckboxSearch
            className={classes.searchField}
            label='Продукт'
            value={siteId}
            options={siteIds}
            onChange={setSiteId}
          />
        ) : null}
        {experts ? (
          <SelectSearch
            className={classes.searchField}
            label={labelExpert}
            value={expert_id}
            options={experts}
            onChange={setExpertId}
          />
        ) : null}
        {isEmail ? (
          <TextField
            className={classes.searchField}
            size={'small'}
            id='outlined-basic-email'
            label='Email'
            variant='outlined'
            value={email}
            onChange={(event) => {
              setEmail(event.target.value)
            }}
          />
        ) : null}
        <BasicDatePicker className={classes.searchField} id={'from'} label='Від' value={from} onChange={setFrom} />
        <BasicDatePicker className={classes.searchField} id={'to'} label='До' value={to} onChange={setTo} />
        {filterTypes ? (
          <FormControl>
            <InputLabel size={'small'} id={`session-select-${id}`}>
              Як користуються
            </InputLabel>
            <Select
              className={classes.searchField}
              size={'small'}
              labelId={`session-select-${id}`}
              id={`session-select-${id}`}
              label='Як користуються'
              value={type}
              onChange={(e) => {
                setType(e.target.value)
              }}
            >
              <MenuItem value={''}>Усі</MenuItem>
              {filterTypes?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
        <Button variant='contained' color='primary' onClick={onSearch}>
          {loadingSearch ? <CircularProgress color='inherit' size={25} /> : 'Ок'}
        </Button>
        <Button
          variant='contained'
          color='error'
          onClick={() => {
            clearFilters()
            handleClearFilters && handleClearFilters()
          }}
        >
          Очистити фільтр
        </Button>
        {typeof total === 'number' ? <Box>Всього: {total}</Box> : null}
      </Stack>
      <TableContainer sx={{ height: `calc(100vh - ${dataPage ? '260px' : ' 220px'})` }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  sx={{ fontWeight: 'bold' }}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              {/* <TableCell /> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => {
              return (
                <TableRow hover role='checkbox' tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    const value = row[column.id]
                    if (column.id === 'link') {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <Link href={value} target={'_blank'}>
                            {value}
                          </Link>
                        </TableCell>
                      )
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {dataPage ? (
        <Pagination
          style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 0' }}
          count={dataPage.pages}
          page={dataPage.page}
          onChange={handleChangePage}
        />
      ) : null}
    </Box>
  )
}
