import React from 'react'
import { TableHead, TableRow, TableCell, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material'
import { useSearch } from '../../hooks/useSearch'
import { getWittenMessages } from '../../store/chatsWritten/actions'
import { useSearchProducts } from '../../hooks/useSearchProducts'
import { STATUSES_WRITTEN } from '../../utils/consts'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { BasicDatePicker } from '../../ui/BasicDatePicker'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch } from '../../store'
import { SelectSearch } from '../../ui/SelectSearch'
import { checkFio } from '../../utils/checkFio'

interface Props {
  search: string
  handleChangeSearch: (value: string) => void
}

export const TableHeadWritten: React.FC<Props> = ({ search, handleChangeSearch }) => {
  const [messageId, setMessageId] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [expert_id, setExpertId] = React.useState('')
  const [product_id, setProductId] = React.useState('')
  const [status, setStatus] = React.useState('')
  const [typeId, setTypeId] = React.useState('')
  const [dateStart, setDateStart] = React.useState<Date | null | undefined>(null)
  const [dateEnd, setDateEnd] = React.useState<Date | null | undefined>(null)

  const { chatsWritten, employees } = useSelector((store: IStore) => store)

  const { handleSearch } = useSearch({ actionThunk: getWittenMessages })
  const { subOptions } = useSearchProducts()
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const navigate = useNavigate()

  const obj = {
    page: String(1),
    message_id: messageId.split('/')[0],
    part_id: messageId.split('/')[1],
    status,
    product_id,
    expert_id: String(expert_id),
    email,
    date_start: dateStart ? moment(dateStart).format('YYYY-MM-DD HH:mm') : '',
    date_end: dateEnd ? moment(dateEnd).format('YYYY-MM-DD HH:mm') : '',
    question: search,
    type_id: typeId,
  }

  React.useEffect(() => {
    const obj = {} as { message_id?: number }
    if (id && !Number.isNaN(Number(id))) {
      obj['message_id'] = Number(id)
      setMessageId(id)
    }
    dispatch(getWittenMessages(obj))
  }, [id])

  React.useEffect(() => {
    if (chatsWritten.page) {
      let message_id = ''
      if (id && !Number.isNaN(Number(id))) {
        message_id = id
        setMessageId(id)
        navigate('/letter')
      }
      handleSearch(String(chatsWritten.page), 'page', { ...obj, message_id })
    }
  }, [chatsWritten.page])

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ fontWeight: 'bold' }} align={'center'}>
          № / Час створення / Джерело
          <TextField
            style={{ width: '150px', marginTop: '10px' }}
            size={'small'}
            id='outlined-basic-id-messageId'
            label='№'
            variant='outlined'
            value={messageId}
            onChange={(event) => {
              setMessageId(event.target.value)
              const ids = event.target.value.split('/')
              handleSearch(ids[0], 'message_id', { ...obj, part_id: ids[1] })
            }}
          />
          <BasicDatePicker
            style={{ width: '150px', marginTop: '10px' }}
            id={'date_start'}
            label='з'
            value={dateStart}
            onChange={(date) => {
              setDateStart(date)
              const date_start = date ? moment(date).format('YYYY-MM-DD HH:mm') : ''
              if (!date_start || moment(date_start).isValid()) {
                handleSearch(date_start, 'date_start', obj)
              }
            }}
          />
          <BasicDatePicker
            style={{ width: '150px', marginTop: '10px' }}
            id={'date_end'}
            label='по'
            value={dateEnd}
            onChange={(date) => {
              setDateEnd(date)
              const date_end = date ? moment(date).format('YYYY-MM-DD HH:mm') : ''
              if (!date_end || moment(date_end).isValid()) {
                handleSearch(date_end, 'date_end', obj)
              }
            }}
          />
          <FormControl sx={{ width: '150px', marginTop: '10px' }}>
            <InputLabel size={'small'} id='simple-select-12'>
              Джерело запитання
            </InputLabel>
            <Select
              size={'small'}
              labelId='simple-select-12'
              id='simple-select-12'
              label='Джерело запитання'
              value={typeId}
              onChange={(e) => {
                setTypeId(e.target.value)
                handleSearch((e.target.value as string) || '', 'type_id', obj)
              }}
            >
              <MenuItem value={''}>Усі</MenuItem>
              <MenuItem value={'0'}>Сайт</MenuItem>
              <MenuItem value={'1'}>Передано з Онлайн</MenuItem>
              <MenuItem value={'3'}>Передано з Техпідтримки</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
        <TableCell sx={{ fontWeight: 'bold' }} align={'center'}>
          Дедлайн
          <hr />
          Відредаговано
        </TableCell>
        <TableCell sx={{ fontWeight: 'bold' }} align={'center'}>
          Контакти особи, що звернулася
          <TextField
            style={{ width: '150px', marginTop: '20px' }}
            size={'small'}
            id='outlined-basic-id-1'
            label='Email'
            variant='outlined'
            value={email}
            onChange={(event) => {
              setEmail(event.target.value)
              handleSearch(event.target.value, 'email', obj)
            }}
          />
        </TableCell>
        <TableCell sx={{ minWidth: '350px', fontWeight: 'bold' }} align={'center'}>
          Запитання
          <TextField
            style={{ width: '250px', marginTop: '20px' }}
            size={'small'}
            id='outlined-basic-id-question'
            label='Запитання'
            variant='outlined'
            value={search}
            onChange={(event) => {
              const value = event.target.value
              if (value.length <= 70) {
                handleChangeSearch(value)
                handleSearch(value, 'question', obj)
              }
            }}
          />
        </TableCell>
        <TableCell sx={{ fontWeight: 'bold' }} align={'center'}>
          Система / Видання
          <FormControl sx={{ width: '150px', marginTop: '20px' }}>
            <InputLabel size={'small'} id='simple-select'>
              Продукт
            </InputLabel>
            <Select
              size={'small'}
              labelId='simple-select'
              id='simple-select'
              label='Продукт'
              value={product_id}
              onChange={(e) => {
                setProductId(e.target.value)
                handleSearch((e.target.value as string) || '', 'product_id', obj)
              }}
            >
              <MenuItem value={''}>Усі</MenuItem>
              {subOptions.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell sx={{ fontWeight: 'bold', zIndex: 3 }} align={'center'}>
          Експерт
          <SelectSearch
            style={{ width: '150px', marginTop: '20px' }}
            label='Експерт'
            value={expert_id}
            options={employees.data?.employees.map((item) => ({ value: String(item.id), label: checkFio(item) })) || []}
            onChange={(value) => {
              setExpertId(value)
              handleSearch(value || '', 'expert_id', obj)
            }}
          />
        </TableCell>
        <TableCell sx={{ fontWeight: 'bold' }} align={'center'}>
          Статус
          <FormControl sx={{ mt: 2 }} fullWidth>
            <InputLabel size={'small'} id='lb3'>
              Статус
            </InputLabel>
            <Select
              style={{ width: '150px' }}
              size={'small'}
              labelId='lb3'
              id='select3'
              value={status}
              label='Статус'
              onChange={(e) => {
                setStatus(e.target.value)
                handleSearch(e.target.value, 'status', obj)
              }}
            >
              <MenuItem value={''}>Всі</MenuItem>
              {Object.keys(STATUSES_WRITTEN).map((key, index) => (
                <MenuItem key={index} value={key}>
                  {STATUSES_WRITTEN[key].title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell sx={{ fontWeight: 'bold' }} align={'center'}>
          Додатково
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
