import React from 'react'
import Box from '@mui/material/Box'
import './App.css'
import CssBaseline from '@mui/material/CssBaseline'
import { Header } from './components/Header'
import { Sidebar } from './components/Sidebar'
import { ThemeProvider } from '@mui/material'
import { customTheme } from './utils/darkTheme'
import { useSelector } from 'react-redux'
import { IStore } from './store/types'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ROUTES } from './utils/routes'
import { Letter } from './components/Letter'
import { Login } from './components/Login'
import { useSocket } from './hooks/useSocket'
import { useAppDispatch } from './store'
import { getUser } from './store/users/actions'
import { getProducts } from './store/products/actions'
import { getTemplates } from './store/templates/actions'
import {
  ChatWorkspaceNews,
  ChatWorkspaceOnline,
  ChatWorkspaceSales,
  ChatWorkspaceTechSupport,
  ChatWorkspaceUnauthorized,
} from './pages/ChatWorkspace'
import { RequireAuth } from './components/HighOrderComponents/RequireAuth'
import { Operators } from './components/Operators'
import { Notifications } from './components/Notifications'
import { getConfigProducts, getConfigurations } from './store/configurations/actions'
import { getEmployees } from './store/employees/actions'
import { RightSidebar } from './components/RightSidebar'
import { AdminCheck } from './components/HighOrderComponents/AdminCheck'
import { Chief, News, OnlineHelper, Sales, TechSupport, Unauthorized } from './components/HighOrderComponents'
import { getTechTemplates } from './store/templatesTechSupport/actions'
import { CHANNELS_ID, Positions, Roles } from './utils/consts'
import { getSalesTemplates } from './store/templatesSales/actions'
import { getNewsTemplates } from './store/templatesNews/actions'
import { getMailingList } from './store/mailing/actions'
import { Marketer } from './components/HighOrderComponents/Marketer'
import { StatNews, StatOnline, StatSales, StatTechSupport, StatUnauthorized, StatWritten } from './pages/Stat'
import {
  OnlineCreateGroup,
  OnlineCreateTamplate,
  OnlineEditGroup,
  OnlineEditTamplate,
  OnlineListTamplates,
} from './pages/templates/templatesOnline'
import {
  SalesCreateGroup,
  SalesCreateTamplate,
  SalesEditGroup,
  SalesEditTamplate,
  SalesListTamplates,
} from './pages/templates/templatesSales'
import {
  TechCreateGroup,
  TechCreateTamplate,
  TechEditGroup,
  TechEditTamplate,
  TechListTamplates,
} from './pages/templates/templatesTechSupport'
import {
  NewsCreateGroup,
  NewsCreateTamplate,
  NewsEditGroup,
  NewsEditTamplate,
  NewsListTamplates,
} from './pages/templates/templatesNews'
import {
  SearchChatMailing,
  SearchChatNews,
  SearchChatOnline,
  SearchChatSales,
  SearchChatTechSupport,
  SearchChatUnauthorized,
} from './pages/SearchChat'
import {
  CurrentDialogNews,
  CurrentDialogOnline,
  CurrentDialogSales,
  CurrentDialogTechSupport,
  CurrentDialogUnauthorized,
} from './pages/CurrentDialog'
import { CreateConfig, EditConfig, ListConfiguration } from './pages/Configurations'
import {
  UnauthorizedCreateGroup,
  UnauthorizedCreateTamplate,
  UnauthorizedEditGroup,
  UnauthorizedEditTamplate,
  UnauthorizedListTamplates,
} from './pages/templates/templatesUnauthorized'
import { CreateEmployee, EditEmployee, ListEmpoyees } from './pages/Employees'
import { getUnauthorizedTemplates } from './store/templatesUnauthorized/actions'
import { CreateAppeal, EditAppeal, ListAppeal } from './pages/Appeal'
import { getAppeal } from './store/appeal/actions'
import { CreateMailing, EditMailing, MailingList, StatisticsView } from './pages/Mailing'
import { UserLogs } from './components/UserLogs'
import { ListLogChats } from './pages/logChats'

export default function App() {
  const { users } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  useSocket()

  React.useEffect(() => {
    setInterval(() => {
      console.log('Активність для уникнення сплячого режиму')
    }, 60000)
  }, [])

  React.useEffect(() => {
    dispatch(getUser())
  }, [dispatch, users.isAuth])

  React.useEffect(() => {
    if (users.isAuth && users.user?.role && users.socket) {
      dispatch(getProducts())
      dispatch(
        getEmployees({
          channels: users.user.channels,
        })
      )
      dispatch(getConfigProducts(''))
      dispatch(getConfigurations())

      if (users.user.channels.includes(String(CHANNELS_ID.ONLINE))) {
        dispatch(getTemplates())
      }

      if (users.user.channels.includes(String(CHANNELS_ID.TECH_SUPPORT))) {
        dispatch(getTechTemplates())
      }

      if (users.user.channels.includes(String(CHANNELS_ID.SALES))) {
        dispatch(getSalesTemplates())
      }

      if (users.user.channels.includes(String(CHANNELS_ID.NEWS))) {
        dispatch(getNewsTemplates())
      }

      if (users.user.channels.includes(String(CHANNELS_ID.UNAUTHORIZED))) {
        dispatch(getUnauthorizedTemplates())
        dispatch(getAppeal())
      }

      if (
        users.user.channels.includes(String(CHANNELS_ID.SALES)) ||
        users.user.channels.includes(String(CHANNELS_ID.ONLINE)) ||
        users.user.channels.includes(String(CHANNELS_ID.NEWS))
      ) {
        if (Notification.permission === 'default') {
          Notification.requestPermission()
        }
      }

      if ([Roles.ADMIN, Roles.CHIEF, Roles.MARKETER].includes(users.user.role)) {
        dispatch(getMailingList({}))
      }
    }
  }, [users.isAuth, users.socket, users.user?.role, dispatch])

  return (
    <ThemeProvider theme={customTheme}>
      <Router>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <Sidebar />
          <RightSidebar />
          <Box component='main' sx={{ flexGrow: 1, p: 0, background: 'rgb(241, 243, 246)', minHeight: '100vh' }}>
            <Header />
            <Routes>
              <Route path={ROUTES.onlineHelp} element={<OnlineHelper element={<ChatWorkspaceOnline />} />} />
              <Route path={ROUTES.chats}>
                <Route index element={<OnlineHelper element={<ChatWorkspaceOnline />} />} />
                <Route path={':id'} element={<OnlineHelper element={<ChatWorkspaceOnline />} />} />
              </Route>
              <Route path={ROUTES.statOnline} element={<RequireAuth element={<StatOnline />} />} />

              <Route path={ROUTES.chatsTechSupport}>
                <Route index element={<TechSupport element={<ChatWorkspaceTechSupport />} />} />
                <Route path={':id'} element={<TechSupport element={<ChatWorkspaceTechSupport />} />} />
              </Route>
              <Route path={ROUTES.statTechSupport} element={<RequireAuth element={<StatTechSupport />} />} />

              <Route path={ROUTES.chatsSales}>
                <Route index element={<Sales element={<ChatWorkspaceSales />} />} />
                <Route path={':id'} element={<Sales element={<ChatWorkspaceSales />} />} />
              </Route>
              <Route path={ROUTES.statSales} element={<RequireAuth element={<StatSales />} />} />

              <Route path={ROUTES.chatsUnauthorized}>
                <Route index element={<Unauthorized element={<ChatWorkspaceUnauthorized />} />} />
                <Route path={':id'} element={<Unauthorized element={<ChatWorkspaceUnauthorized />} />} />
              </Route>
              <Route path={ROUTES.statUnauthorized} element={<RequireAuth element={<StatUnauthorized />} />} />

              <Route path={ROUTES.chatsNews}>
                <Route index element={<News element={<ChatWorkspaceNews />} />} />
                <Route path={':id'} element={<News element={<ChatWorkspaceNews />} />} />
              </Route>
              <Route path={ROUTES.statNews} element={<RequireAuth element={<StatNews />} />} />

              <Route path={ROUTES.templates}>
                <Route index element={<OnlineHelper element={<></>} />} />
                <Route path={'group/create'} element={<OnlineHelper element={<OnlineCreateGroup />} />} />
                <Route path={'group/edit/:id'} element={<OnlineHelper element={<OnlineEditGroup />} />} />
                <Route path={'group/:id'} element={<OnlineHelper element={<OnlineListTamplates />} />} />
                <Route path={'template/create/:id'} element={<OnlineHelper element={<OnlineCreateTamplate />} />} />
                <Route path={'template/:id'} element={<OnlineHelper element={<OnlineEditTamplate />} />} />
              </Route>

              <Route path={ROUTES.templatesSales}>
                <Route index element={<Sales element={<></>} />} />
                <Route path={'group/create'} element={<Sales element={<SalesCreateGroup />} />} />
                <Route path={'group/edit/:id'} element={<Sales element={<SalesEditGroup />} />} />
                <Route path={'group/:id'} element={<Sales element={<SalesListTamplates />} />} />
                <Route path={'template/create/:id'} element={<Sales element={<SalesCreateTamplate />} />} />
                <Route path={'template/:id'} element={<Sales element={<SalesEditTamplate />} />} />
              </Route>

              <Route path={ROUTES.templatesUnauthorized}>
                <Route index element={<Unauthorized element={<></>} />} />
                <Route path={'group/create'} element={<Unauthorized element={<UnauthorizedCreateGroup />} />} />
                <Route path={'group/edit/:id'} element={<Unauthorized element={<UnauthorizedEditGroup />} />} />
                <Route path={'group/:id'} element={<Unauthorized element={<UnauthorizedListTamplates />} />} />
                <Route
                  path={'template/create/:id'}
                  element={<Unauthorized element={<UnauthorizedCreateTamplate />} />}
                />
                <Route path={'template/:id'} element={<Unauthorized element={<UnauthorizedEditTamplate />} />} />
              </Route>

              <Route path={ROUTES.templatesTechSupport}>
                <Route index element={<TechSupport element={<></>} />} />
                <Route path={'group/create'} element={<TechSupport element={<TechCreateGroup />} />} />
                <Route path={'group/edit/:id'} element={<TechSupport element={<TechEditGroup />} />} />
                <Route path={'group/:id'} element={<TechSupport element={<TechListTamplates />} />} />
                <Route path={'template/create/:id'} element={<TechSupport element={<TechCreateTamplate />} />} />
                <Route path={'template/:id'} element={<TechSupport element={<TechEditTamplate />} />} />
              </Route>

              <Route path={ROUTES.templatesNews}>
                <Route index element={<News element={<></>} />} />
                <Route path={'group/create'} element={<News element={<NewsCreateGroup />} />} />
                <Route path={'group/edit/:id'} element={<News element={<NewsEditGroup />} />} />
                <Route path={'group/:id'} element={<News element={<NewsListTamplates />} />} />
                <Route path={'template/create/:id'} element={<News element={<NewsCreateTamplate />} />} />
                <Route path={'template/:id'} element={<News element={<NewsEditTamplate />} />} />
              </Route>

              <Route path={ROUTES.settings}>
                <Route
                  index
                  element={
                    <OnlineHelper element={<Operators channelId={CHANNELS_ID.ONLINE} positions={Positions} />} />
                  }
                />
                <Route path={ROUTES.notification} element={<OnlineHelper element={<Notifications isPopup />} />} />
                <Route
                  path={ROUTES.logs}
                  element={<OnlineHelper element={<UserLogs channels={CHANNELS_ID.ONLINE} />} />}
                />
              </Route>

              <Route path={ROUTES.settingsTech}>
                <Route
                  index
                  element={
                    <TechSupport element={<Operators channelId={CHANNELS_ID.TECH_SUPPORT} positions={Positions} />} />
                  }
                />
                <Route path={ROUTES.notification} element={<TechSupport element={<Notifications />} />} />
                <Route
                  path={ROUTES.logs}
                  element={<OnlineHelper element={<UserLogs channels={CHANNELS_ID.TECH_SUPPORT} />} />}
                />
              </Route>

              <Route path={ROUTES.settingsSales}>
                <Route
                  index
                  element={<Sales element={<Operators channelId={CHANNELS_ID.SALES} positions={Positions} />} />}
                />
                <Route path={ROUTES.notification} element={<Sales element={<Notifications isPopup />} />} />
                <Route
                  path={ROUTES.logs}
                  element={<OnlineHelper element={<UserLogs channels={CHANNELS_ID.SALES} />} />}
                />
              </Route>

              <Route path={ROUTES.settingsUnauthorized}>
                <Route index element={<Unauthorized element={<ListAppeal />} />} />
                <Route path={ROUTES.appeal} element={<Unauthorized element={<CreateAppeal />} />} />
                <Route path={`${ROUTES.appeal}/:id`} element={<Unauthorized element={<EditAppeal />} />} />
                <Route path={ROUTES.notification} element={<Unauthorized element={<Notifications isPopup />} />} />
                <Route
                  path={ROUTES.logs}
                  element={<OnlineHelper element={<UserLogs channels={CHANNELS_ID.UNAUTHORIZED} />} />}
                />
              </Route>

              <Route path={ROUTES.settingsNews}>
                <Route
                  index
                  element={<News element={<Operators channelId={CHANNELS_ID.NEWS} positions={Positions} />} />}
                />
                <Route path={ROUTES.notification} element={<News element={<Notifications isPopup />} />} />
                <Route
                  path={ROUTES.logs}
                  element={<OnlineHelper element={<UserLogs channels={CHANNELS_ID.NEWS} />} />}
                />
              </Route>

              <Route path={ROUTES.search}>
                <Route index element={<OnlineHelper element={<SearchChatOnline />} />} />
                <Route path={':id'} element={<OnlineHelper element={<CurrentDialogOnline />} />} />
              </Route>

              <Route path={ROUTES.searchTechSupport}>
                <Route index element={<TechSupport element={<SearchChatTechSupport />} />} />
                <Route path={':id'} element={<TechSupport element={<CurrentDialogTechSupport />} />} />
              </Route>

              <Route path={ROUTES.searchSales}>
                <Route index element={<Sales element={<SearchChatSales />} />} />
                <Route path={':id'} element={<Sales element={<CurrentDialogSales />} />} />
              </Route>

              <Route path={ROUTES.searchUnauthorized}>
                <Route index element={<Unauthorized element={<SearchChatUnauthorized />} />} />
                <Route path={':id'} element={<Unauthorized element={<CurrentDialogUnauthorized />} />} />
              </Route>

              <Route path={ROUTES.searchNews}>
                <Route index element={<News element={<SearchChatNews />} />} />
                <Route path={':id'} element={<News element={<CurrentDialogNews />} />} />
              </Route>

              <Route path={ROUTES.letter}>
                <Route index element={<RequireAuth element={<Letter />} />} />
                <Route path={':id'} element={<RequireAuth element={<Letter />} />} />
              </Route>
              <Route path={ROUTES.statWritten} element={<RequireAuth element={<StatWritten />} />} />

              <Route path={ROUTES.employees}>
                <Route index element={<Chief element={<ListEmpoyees />} />} />
                <Route path={'new'} element={<Chief element={<Chief element={<CreateEmployee />} />} />} />
                <Route path={':id'} element={<Chief element={<EditEmployee />} />} />
              </Route>

              <Route path={ROUTES.mailing}>
                <Route index element={<Marketer element={<MailingList />} />} />
                <Route path={'new'} element={<Marketer element={<CreateMailing />} />} />
                <Route path={'edit/:id'} element={<Marketer element={<EditMailing />} />} />
                <Route path={'statistics/:id'} element={<Marketer element={<StatisticsView />} />} />
                <Route path={'chats'} element={<Marketer element={<SearchChatMailing />} />} />
                <Route path={'chats/:id'} element={<Marketer element={<CurrentDialogNews />} />} />
              </Route>

              <Route path={ROUTES.configurators}>
                <Route index element={<AdminCheck element={<ListConfiguration />} />} />
                <Route path={'new'} element={<AdminCheck element={<AdminCheck element={<CreateConfig />} />} />} />
                <Route path={':id'} element={<AdminCheck element={<EditConfig />} />} />
              </Route>
              <Route path={ROUTES.logChats} element={<AdminCheck element={<ListLogChats />} />} />
              <Route path={ROUTES.login} element={<Login />} />
            </Routes>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  )
}
// test admin client
